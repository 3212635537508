var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "projectChart" },
    [
      _c(
        "el-card",
        { staticStyle: { width: "100%" } },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header"
            },
            [_c("span", [_vm._v("心肺九图")])]
          ),
          _vm._l(_vm.images, function(url) {
            return _c("el-image", {
              key: url,
              attrs: { src: url, fit: "fill" }
            })
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }