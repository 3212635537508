<template>
  <div class="projectChart">
    <el-card style="width: 100%">
      <div slot="header" class="clearfix">
        <span>心肺九图</span>
      </div>
      <el-image
        v-for="url in images"
        :key="url"
        :src="url"
        fit="fill"
      ></el-image>
    </el-card>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    height: "600px",
    checkKey: "",
  },
  watch: {
    checkKey: {
      handler(v) {
        this.loadJiuTu();
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      images: [],
    };
  },
  methods: {
    loadJiuTu() {
      this.images = [];
      let refresh = "?" + new Date().getTime();
      if (this.checkKey) {
        this.images = [];
        for (let i = 1; i < 9; i++) {
          this.images.push(
            this.baseUrl +
              "report/" +
              this.checkKey +
              `/heartLung/heartLung${i}.png` +
              refresh
          );
        }
      }
    },
  },
  mounted() {
    this.loadJiuTu();
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.projectChart {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  .images {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    .item {
    }
  }
}
</style>