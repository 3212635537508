<template>
  <div class="projectChart">
    <echart chartID="radchart" ref="radchart" :chartHeight="height"></echart>
  </div>
</template>
<script>
import echart from "@/components/echart";
import * as echarts from "echarts";
import { saveCheckImage } from "@/api/check/check.js";
export default {
  components: { echart },
  props: {
    rad: {
      default: {
        indicators: [],
        datas: [80, 90, 70, 99],
      },
    },
    height: "600px",
  },
  watch: {
    value: {
      handler(v) {
        if (v) {
          this.loadRad();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    //显示雷达数据
    loadRad() {
      if (this.rad) {
        let indicators = this.rad.indicators;
        let datas = this.rad.datas;
        this.$refs.radchart.showChart(this.getRadOption(indicators, datas));
      }
    },
    getRadOption(indicators, datas) {
      let radOption = {
        color: ["#CC6699"],
        radar: {
          indicator: indicators,
          radius:'52%',
          name: {
            textStyle: { color: "#A0522D", fontSize: 20 },
          },
        },
        series: [
          {
            type: "radar",
            emphasis: {
              lineStyle: {
                width: 4,
              },
            },
            data: [
              {
                symbol: "roundRect",
                symbolSize: 16,
                value: datas,
                label: {
                  show: true,
                  position: "inside",
                  fontSize: 14,
                  color: "#ffffff",
                  fontWeight: "bolder",
                  formatter: function (params) {
                    return params.value;
                  },
                },
                areaStyle: {
                  color: new echarts.graphic.RadialGradient(0.1, 0.6, 1, [
                    {
                      color: "rgba(255, 145, 124, 0.2)",
                      offset: 0,
                    },
                    {
                      color: "rgba(255, 145, 124, 0.9)",
                      offset: 1,
                    },
                  ]),
                },
                itemStyle: {
                  // 单个拐点标志的样式设置。
                  normal: {
                    borderColor: "#CC6699",
                    // 拐点的描边颜色。[ default: '#000' ]
                    borderWidth: 3,
                    // 拐点的描边宽度，默认不描边。[ default: 0 ]
                  },
                },
                lineStyle: {
                  //雷达线条样式
                  type: "dashed",
                },
              },
            ],
          },
        ],
      };
      return radOption;
    },
    postImage(key) {
      let imageData = this.$refs.radchart.getImageData();
      let param = { key: key, type: "Evaluate", image: imageData };
      saveCheckImage(param).then((res) => {
        if (res) {
        }
      });
    },
    getImageData() {
      let imageData = this.$refs.radchart.getImageData();
      let param = { type: "Evaluate", image: imageData };
      return param;
    },
  },
  mounted() {
    this.loadRad();
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.projectChart {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>