<template>
  <el-drawer :title="formTitle" size="100%" :visible.sync="formVisible">
    <checkHeader ref="checkHeader" v-model="checkInfo" :readOnly="true" />
    <div class="component-container">
      <div class="tabContainer">
        <el-tabs v-model="checkType" tab-position="left">
          <el-tab-pane name="Evaluate">
            <span slot="label"
              ><i class="el-icon-check"
                >运动健康指数【{{ checkInfo.Score }}】</i
              >
            </span>
            <checkReportEvaluate :rad="evaluateRad" ref="Evaluate" />
          </el-tab-pane>
          <el-tab-pane
            name="HeartEcg"
            v-if="heartEcg && heartEcg.Key"
          >
            <span slot="label"
              ><i class="el-icon-check"
                >心电图运动负荷试验【{{ heartEcg.Result }}】</i
              >
            </span>
            <checkEditorHeartEcg
              :disable="true"
              v-model="heartEcg"
            />
          </el-tab-pane>
          <el-tab-pane
            name="BodyComposition"
            v-if="bodyComposition && bodyComposition.Key"
          >
            <span slot="label"
              ><i class="el-icon-check"
                >人体成分分析【{{ bodyComposition.Score }}】</i
              >
            </span>
            <checkEditorBodyComposition
              :disable="true"
              v-model="bodyComposition"
            />
          </el-tab-pane>
          <el-tab-pane
            name="FootPressure"
            v-if="footPressure && footPressure.Key"
          >
            <span slot="label"
              ><i class="el-icon-check"
                >足底健康评估【{{ footPressure.Score }}】</i
              >
            </span>
            <checkReportFootFootPressure
              :disable="true"
              ref="checkReportFootFootPressure"
              :checkKey="checkKey"
              :footPressure="footPressure"
            />
          </el-tab-pane>
          <el-tab-pane name="KneeHurt" v-if="kneeHurt && kneeHurt.Key">
            <span slot="label"
              ><i class="el-icon-check">关节功能诊断【{{ kneeHurt.Score }}】</i>
            </span>
            <checkReportKneeHurt
              :checkKey="checkKey"
              :rad1="kneeHurtRad1"
              :rad2="kneeHurtRad2"
              ref="checkReportKneeHurt"
            />
          </el-tab-pane>
          <el-tab-pane name="RunPosture" v-if="runPosture && runPosture.Key">
            <span slot="label"
              ><i class="el-icon-check"
                >三维动作捕捉【{{ runPosture.Score.toFixed(0) }}】</i
              >
            </span>
            <checkEditorRunPosture v-model="runPosture" :disable="true" />
          </el-tab-pane>
          <el-tab-pane name="Breath" v-if="breath && breath.Key">
            <span slot="label"
              ><i class="el-icon-check">呼吸功能评估【{{ breath.Score }}】</i>
            </span>
            <checkEditorBreath ref="breath" :disable="true" v-model="breath" />
          </el-tab-pane>
          <el-tab-pane name="HeartLung" v-if="heartLung && heartLung.Key">
            <span slot="label"
              ><i class="el-icon-check"
                >心肺耐力试验【{{ heartLung.Score }}】</i
              >
            </span>
            <checkReportHeartLung
              v-if="heartLung.Key"
              ref="checkReportHeartLung"
              :checkKey="checkKey"
            />
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="btns">
        <el-button type="warning" @click="getReport">生成评估报告</el-button>
        <el-button
          type="success"
          v-if="checkInfo.ReportFile"
          @click="downReport"
          >下载评估报告</el-button
        >
        <el-button type="warning" @click="getPrescReport"
          >生成改善方案</el-button
        >
        <el-button
          type="danger"
          v-if="checkInfo.PrescriptionFile"
          @click="downReport2"
          >下载改善方案</el-button
        >
        <el-switch
          v-if="checkInfo.PrescriptionFile"
          active-text="开放"
          active-color="#13ce66"
          inactive-color="#ff4949"
          inactive-text="关闭"
          v-model="checkInfo.ShowPrescription"
          @change="handleAllow"
        ></el-switch>
      </div>
    </div>
    <checkPrescReportType
      ref="checkPrescReportType"
      @update="prescReportUpdate"
    />
  </el-drawer>
</template>
<script>
import checkReportEvaluate from "./checkReportEvaluate";
import checkHeader from "./checkHeader.vue";
import checkPrescReportType from "./checkPrescReportType.vue";
import checkEditorBodyComposition from "./checkEditorBodyComposition.vue";
import checkReportFootFootPressure from "./checkReportFootFootPressure.vue";
import checkReportKneeHurt from "./checkReportKneeHurt";
import checkEditorRunPosture from "./checkEditorRunPosture.vue";
import checkEditorBreath from "./checkEditorBreath.vue";
import checkReportHeartLung from "./checkReportHeartLung";
import checkEditorHeartEcg from "./checkEditorHeartEcg";

import {
  getLocalCheck,
  generateReport,
  setPrescription,
} from "@/api/check/check.js";

export default {
  components: {
    checkReportEvaluate,
    checkPrescReportType,
    checkHeader,

    checkEditorBodyComposition,
    checkEditorRunPosture,
    checkEditorBreath,
    checkReportKneeHurt,
    checkReportHeartLung,
    checkReportFootFootPressure,
    checkEditorHeartEcg
  },
  props: {},
  data() {
    return {
      formVisible: false,
      formTitle: "生成报告",
      checkKey: "",
      checkType: "Evaluate",
      checkInfo: {
        Member: "",
      },
      //心电图
      heartEcg:{},
      //体成分
      bodyComposition: {},
      //足底压力
      footPressure: {},
      //膝关节损伤
      kneeHurt: {},
      //步态分析
      runPosture: {},
      //心肺实验
      heartLung: {},
      //呼吸功能
      breath: {},
      //健康指数
      evaluateRad: {
        indicators: [],
        datas: [],
      },
      kneeHurtRad1: {
        indicators: [
          { name: "俯卧屈膝髋关节内旋", max: 5 },
          { name: "踝关节灵活性", max: 5 },
          { name: "俯卧髋关节主动伸展", max: 5 },
          { name: "俯卧膝关节主动屈曲", max: 5 },
          { name: "主动直腿上抬", max: 5 },
        ],
        datas: [2, 3, 2, 2, 5],
      },
      kneeHurtRad2: {
        indicators: [
          { name: "单腿下蹲", max: 5 },
          { name: "动作控制", max: 5 },
          { name: "髋关节铰链", max: 5 },
          { name: "落地缓冲", max: 5 },
          { name: "深蹲", max: 5 },
        ],
        datas: [2, 3, 2, 2, 5],
      },
    };
  },
  methods: {
    show(row) {
      this.checkKey = row.Key;
      this.getData();
    },
    //获取
    getData() {
      let param = {
        checkKey: this.checkKey,
      };
      getLocalCheck(param).then((res) => {
        this.checkInfo = res.check;
        this.bodyComposition = res.bodyComposition;
        this.footPressure = res.footPressure;
        this.kneeHurt = res.kneeHurt;
        this.heartLung = res.heartLung;
        this.runPosture = res.runPosture;
        this.breath = res.breath;
        this.formTitle = this.checkInfo.Member.RealName;
        let evalRadDatas = [];
        let evalRadIndicators = [];
        evalRadIndicators.push({ name: "人体成分评估", max: 100 });
        if (this.bodyComposition && this.bodyComposition.Key) {
          evalRadDatas.push(this.bodyComposition.Score);
        } else {
          evalRadDatas.push(0);
        }
        // evalRadIndicators.push({ name: "足底健康评估", max: 100 });
        // if (this.footPressure && this.footPressure.Key) {
        //   evalRadDatas.push(Math.round(this.footPressure.Score));
        // } else {
        //   evalRadDatas.push(0);
        // }
        evalRadIndicators.push({ name: "关节功能评估", max: 100 });
        if (this.kneeHurt && this.kneeHurt.Key) {
          evalRadDatas.push(Math.round(this.kneeHurt.Score));
        } else {
          evalRadDatas.push(0);
        }
        // evalRadIndicators.push({ name: "三维动作捕捉", max: 100 });
        // if (this.runPosture && this.runPosture.Key) {
        //   evalRadDatas.push(Math.round(this.runPosture.Score));
        // } else {
        //   evalRadDatas.push(0);
        // }
        evalRadIndicators.push({ name: "心肺耐力评估", max: 100 });
        if (this.heartLung && this.heartLung.Key) {
          evalRadDatas.push(Math.round(this.heartLung.Score));
        } else {
          evalRadDatas.push(0);
        }
        evalRadIndicators.push({ name: "呼吸功能评估", max: 100 });
        if (this.breath && this.breath.Key) {
          evalRadDatas.push(Math.round(this.breath.Score));
        } else {
          evalRadDatas.push(0);
        }
        this.evaluateRad.datas = evalRadDatas;
        this.evaluateRad.indicators = evalRadIndicators;
        if (this.kneeHurt && this.kneeHurt.Key) {
          let kneeHurtDatas1 = [];
          kneeHurtDatas1.push(this.kneeHurt.TotalFwqxgjnx); //俯卧屈膝髋关节内旋
          kneeHurtDatas1.push(this.kneeHurt.TotalHgjlhx); //踝关节灵活性
          kneeHurtDatas1.push(this.kneeHurt.TotalFwzdsk); //俯卧髓关节主动伸展
          kneeHurtDatas1.push(this.kneeHurt.TotalFwxgjzdqq); //俯卧膝关节主动屈曲
          kneeHurtDatas1.push(this.kneeHurt.TotalZdztst); //主动直腿上抬
          this.kneeHurtRad1.datas = kneeHurtDatas1;
          let kneeHurtDatas2 = [];
          kneeHurtDatas2.push(this.kneeHurt.TotalGjb); //单腿下蹲
          kneeHurtDatas2.push(this.kneeHurt.TotalBydtzlwdx); //动作控制
          kneeHurtDatas2.push(this.kneeHurt.TotalKgjjl); //髋关节铰链
          kneeHurtDatas2.push(this.kneeHurt.TotalLdhc); //落地缓冲
          kneeHurtDatas2.push(this.kneeHurt.TotalSd); //深蹲
          this.kneeHurtRad2.datas = kneeHurtDatas2;
        }
        this.formVisible = true;
      });
    },
    handleAllow() {
      let param = {
        key: this.checkInfo.Key,
        allow: this.checkInfo.ShowPrescription,
      };
      setPrescription(param).then((res) => {
        if (res) {
          this.msg.successMsg("操作成功！");
        }
      });
    },
    getPrescReport() {
      let confirmMsg = "请确认所有测评已完成，确认生成处方报告吗？";
      if (this.checkInfo.PrescriptionFile) {
        confirmMsg = "处方报告已生成过可直接下载查看，确认重新生成吗？";
      }
      this.$confirm(confirmMsg, "提示", {}).then(() => {
        this.$refs.checkPrescReportType.show(this.checkKey);
      });
    },
    prescReportUpdate(val) {
      that.checkInfo.PrescriptionFile = val;
    },
    getReport() {
      let confirmMsg =
        "请确认所有测评已完成，并预览了综合评估和下肢功能，确认生成吗？";
      if (this.checkInfo.ReportFile) {
        confirmMsg =
          "报告已生成过可直接下载查看，如重新生成请确认已预览了综合评估和关节功能评估，确认重新生成吗？";
      }
      this.$confirm(confirmMsg, "提示", {}).then(() => {
        let images = [];
        let evaluateImage = this.$refs.Evaluate.getImageData();
        images.push(evaluateImage);
        if ((this.checkInfo.CheckTypes & 8) == 8) {
          let legImage1 = this.$refs.checkReportKneeHurt.getImageData1();
          let legImage2 = this.$refs.checkReportKneeHurt.getImageData2();
          images.push(legImage1);
          images.push(legImage2);
        }
        let that = this;
        generateReport({ key: this.checkInfo.Key, images: images })
          .then((res) => {
            if (res) {
              that.checkInfo.ReportFile = res.data;
              let fileurl = that.baseUrl + res.data;
              window.open(fileurl, "_blank");
            }
          })
          .catch((ex) => {
            this.msg.errorMsg("生成报告失败！");
          });
      });
    },
    downReport() {
      if (this.checkInfo.ReportFile) {
        let fileurl = this.baseUrl + this.checkInfo.ReportFile;
        window.open(fileurl, "_blank");
      }
    },
    downReport2() {
      if (this.checkInfo.PrescriptionFile) {
        let fileurl = this.baseUrl + this.checkInfo.PrescriptionFile;
        window.open(fileurl, "_blank");
      }
    },
  },
  mounted() {},
};
</script>
<style lang='scss' scoped>
/deep/.el-drawer__header {
  margin-bottom: 10px;
  font-size: 16px;
  color: #ff862f;
}
/deep/.el-drawer__body {
  overflow-y: scroll;
  background-color: #f5f5f5;
}
/deep/.el-form {
  margin-top: 15px;
}
/deep/.el-collapse-item__content {
  padding: 0 20px 20px 20px;
}
/deep/.el-collapse-item__header {
  padding-left: 20px;
}
/deep/.el-tabs {
  height: 100%;
}
/deep/.el-card__header {
  font-size: 16px;
  font-weight: bold;
  color: #60430d;
}
/deep/.el-tabs__content {
  height: 100%;
  overflow-y: auto;
}
/deep/.el-form-item {
  margin-bottom: 10px;
}
/deep/.el-form-item__error {
  padding-top: 0 !important;
}
/deep/.is-disabled {
  .el-input__inner {
    color: #333 !important;
  }
}
.done {
  color: #008000;
  font-size: 18px;
  font-weight: bolder;
}
.wait {
  color: #ffa500;
}
.component-container {
  width: 100%;
  height: auto;
  .form {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
  .form-item {
    .form-item-label {
      text-align: center;
      padding: 10px 0;
    }
  }
  .btns {
    padding: 10px;
    width: 100%;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 100;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
  }
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  /deep/.el-card {
    flex: 1;
    margin-right: 10px;
    height: inherit !important;
    .el-card__body {
      // text-align: center;
      .box-card-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
  &:last-child {
    padding-bottom: 60px;
  }
}
</style>